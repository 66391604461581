

import { priceFormatterV3 } from '../../settings/themeSettings';
import './DashboardTable.scss';


const DashboardTable = ({ caption, data, title }) => {



    return <table class='dashboard-table'>
        <caption> {caption} </caption>
        {title && <thead>
            <th></th>
            {title.map((tl) => <th>{tl}</th>)}
        </thead>}
        <tbody>
            {
                data.map((item, idx) => <tr key={idx}>
                    <td>{item.title}</td>
                    {Array.isArray(item.value) ? item.value.map((v, key) => <td key={key} style={{textAlign: isNaN(Number(v))? 'left' : 'right'}} >{priceFormatterV3(v) }</td>) : <td style={{textAlign: isNaN(Number(item.value))? 'left' : 'right'}}>{priceFormatterV3(item.value) }</td>}
                </tr>

                )
            }
        </tbody>

    </table>;
}



export default DashboardTable;