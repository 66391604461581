
import { Button, Typography } from '@mui/material';
import './voucher.scss';
import { FilterAlt, Refresh } from '@mui/icons-material';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { COLORS } from '../../settings/themeSettings';
import SearchIcon from '@mui/icons-material/Search';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { fetchVoucher } from '../../data/slices/voucherSlice';
import StyledTable from '../../components/table-component/StyledTable';
import { useParams } from 'react-router';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import VoucherPDF from '../../components/vouvherPDF/VoucherPDF';

import { dateFormatter } from "../../settings/themeSettings";
import { fetchCircuit } from '../../data/slices/circuitSlice';



const Vouchers = (props) => {


    const { voucher, voucherFetchStatus } = useSelector((state) => state.vouchers);
    const [ dossier, setDossier ] = useState({});
    const dispatch = useDispatch();
    const { id, type, vid } = useParams();

    useEffect(() => {
        dispatch(fetchVoucher(vid));
    }, []);

    useEffect(() => {
        if (voucher) {
            fetchCircuit(id)
                .then((response) => {

                    if (response.status > 201) {
                        throw response;
                    }

                    return response.json();
                })
                .then(async (resp) => {
                    console.log(resp);
                    setDossier(resp);
                }).catch((err) => {
                    console.log(err);
                });
        }
    }, [voucher])




    return (<div className="circuit-page">
        <div className="header">
            <div className="title">
                Details voucher
            </div>


            <Button
                variant="contained"
                color="secondary"
                disabled={!(voucher && voucher.prestataire?.courriel)}
                onClick={() => {
                    console.log(voucher);
                    let voucherbody = `
Bonjour, 

Merci de confirmer la réservation suivante: N° ${ dossier?.reference ?? '----'} .

[ Hebergement ]\n
${voucher.sejours.map((s) => `Du ${dateFormatter(s.dateDebut)} au ${dateFormatter(s.dateFin)}, régime : ${s.regime}, pax : ${s.pax}, nuitée : ${s.nuitee}, type de chambre: ${s.typeChambre}.\n`)}

[ Rooming ]
${voucher.sejours.length > 0 ? `${`
${voucher.sejours[0].circuit.isGroup ? `${voucher.sejours[0].circuit.groups.map((r) => `Groupe :  ${r.libelle}, Pax :  ${r.pax} \n`)}` : `${voucher.sejours[0].circuit.rooming.map((r) => `${r.civilite}  ${r.nom} ${r.prenom} \n`)}`
                            }
`

                            }` : ``
                        }
Dans l'attente de votre confirmation avec la facture pro-forma.


Cordialement. `;





                    const link = "mailto:"
                        + "?to=" + voucher.prestataire?.courriel
                        + "&subject=" + encodeURIComponent(`Résa N° ${ dossier?.reference ?? '----'}` +" de L'Africa 4 Tourism")
                        + "&body=" + encodeURIComponent(voucherbody)
                        ;

                    window.location.href = link;
                }} >
                Envoyer voucher par E-mail

            </Button>




        </div>


        {
            (voucherFetchStatus == 'loading') ?
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <PDFViewer style={{ width: '100%', height: '100vh' }} >
                    <VoucherPDF voucher={voucher} />
                </PDFViewer>
        }

    </div>);
}



export default Vouchers;
