import _ from "lodash";
import EditableTable from "../../components/editableTable/EditableTable";
import UpdatedTable from "../../components/updated-table/StyledTable";
import { useEffect, useState } from "react";
import { COLORS, priceFormatter, toEuro } from "../../settings/themeSettings";
import { Box, Button, Dialog, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { FileDownloadOutlined, Save, SaveAlt } from "@mui/icons-material";
import { BlobProvider } from "@react-pdf/renderer";
import NJournalPDF from "../../components/JournalPDF/NJournalPDF";



import './calculator.scss';
import { notify } from "../../settings/toastSetting";
import { createCotation, fetchCotations } from "../../data/slices/cotationSlice";
import { useDispatch, useSelector } from "react-redux";
import StyledTable from "../../components/table-component/StyledTable";


const HotelsTable = ({ hotels = [], onHotelChanged = () => { } }) => {

    const [blink, setBlink] = useState(false);
    useEffect(() => {
        setBlink(false);
        setTimeout(() => {
            setBlink(true);
        }, 500);
    }, [hotels]);

    return blink ? <div>
        <div className="_title">Charge Individuelle</div>
        <EditableTable
            data={hotels}
            reference={{ pax: '', supSgle: '', prestation: '', tarif: '', regime: '', total: '' }}
            onUpdate={onHotelChanged}
        //  operations={{ total: { labels: ['pax', 'tarif'], type: '*' } }}

        />
    </div> : ''
}


const FraisTable = ({ frais = [], onFraisChanged = () => { } }) => {

    const [blink, setBlink] = useState(false);
    useEffect(() => {
        setBlink(false);
        setTimeout(() => {
            setBlink(true);
        }, 500);
    }, [frais]);
    return blink ? <div>
        <div className="_title">Charge Collective</div>

        <EditableTable
            data={frais}
            reference={{ frais: '', '2-3Pax': '', '4-8Pax': '', '9-20Pax': '', '21-30Pax': '', '31-50Pax': '' }}
            onUpdate={onFraisChanged} />
    </div> : '';
}


const SaveDialog = ({ show = false, onSave = () => { }, handleClose = () => { } }) => {

    const [title, setTitle] = useState("")

    return <Dialog onClose={handleClose} open={show} maxWidth={'lg'} minWidth={'700px'} >
        <DialogTitle
            sx={{
                backgroundColor: COLORS.secondary,
                color: 'white',
                mb: 5
            }}
        >Enregistrer la cotation</DialogTitle>


        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '400px',
                padding: '10px 0'

            }}

        >

            <Box
                width={'300px'}
            >
                <Typography
                    variant="p"

                >
                    Ajoutez un nom
                </Typography>

                <TextField

                    margin="normal"
                    required
                    fullWidth
                    name="nom"
                    label="nom"
                    InputProps={{ style: { height: '45px' } }}
                    color="tertiary"
                    focused
                    placeholder="Entrez un nom"
                    onChange={(e) => setTitle(e.target.value)}
                />


            </Box>

            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                    marginTop: 2,
                    width: '100%'
                }}

            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSave(title)}
                >
                    Valider
                </Button>

            </Stack>


        </Stack>

    </Dialog>
}


const Calculator = (props) => {

    const [outputs, setOutputs] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [frais, setFrais] = useState([]);

    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const { cotations } = useSelector((state) => state.cotations);
    const dispatch = useDispatch();
    useEffect(() => {

        setOutputs(_.range(1, 51).map((i) => ({ pax: i })));
        dispatch(fetchCotations());

    }, []);

    const onHotelsChanged = (update) => setHotels(update);
    const onFraisChanged = (update) => setFrais(update);
    const totalheb = (pax) => {

        return hotels.reduce((sum, current) => {

            if (pax == current.pax /* || current.pax == 1 */) {
                return ({ tarif: sum.tarif + Number(current.tarif) })
            } else {
                return sum;
            }



        }, { tarif: 0 }).tarif;
    }


    useEffect(() => {

        console.log(hotels);
        hotels.length > 0 && setOutputs(outputs.map((output, idx) => {
            return { ...output, hebergement: totalheb(output.pax), totalCFA: totalheb(output.pax) + (output.transport || 0), totalEuro: toEuro(totalheb(output.pax) + (output.transport || 0)), '15%': toEuro((totalheb(output.pax) + (output.transport || 0)) / 0.85), '20%': toEuro((totalheb(output.pax) + (output.transport || 0)) / 0.85) }
        }));



    }, [hotels]);
    useEffect(() => {

        frais.length > 0 && setOutputs(outputs.map((output, idx) => {


            if (output.pax >= 1 && output.pax <= 3) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['2-3Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)), '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }


            if (output.pax >= 4 && output.pax <= 8) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['4-8Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: Number(transport) + Number(output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)), '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }

            if (output.pax >= 9 && output.pax <= 20) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['9-20Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)), '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }
            if (output.pax >= 21 && output.pax <= 30) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['21-30Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)), '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }


            if (output.pax >= 31 && output.pax <= 50) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['31-50Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)), '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }
            return { ...output, transport: 0 };


        }));

        console.log(frais);
    }, [frais]);



    return <>

        <Stack
            direction="row"
            justifyContent="flex-start"
            p="5"
        >





            <div className="btn"
                style={{
                    margin: '10px 10px 0 0'
                }}
                onClick={() => {
                    setShowSaveDialog(true);
                }}
            >
                <Save style={{ fill: COLORS.secondary }} />
                Enregistrer

            </div>
            <div className="btn"
                style={{
                    margin: '10px 0'
                }}
                onClick={() => {
                    document.getElementById('journalv')?.click();
                }}
            >
                <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                <BlobProvider document={
                    <NJournalPDF datas={[hotels, frais, outputs]} heads={[
                        Object.keys({ pax: '', supSgle: '', prestation: '', tarif: '', regime: '', total: '' }),
                        Object.keys({ frais: '', '2-3Pax': '', '4-8Pax': '', '9-20Pax': '', '21-30Pax': '', '31-50Pax': '' }),
                        ['pax', 'hebergement', 'transport', 'totalCFA', 'totalEuro', '15%']
                    ]}
                        title="Programme"
                    />
                }>
                    {({ blob, url }) => (
                        <a href={url}
                            id=''
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                fontSize: '1em',
                                color: COLORS.secondary,
                                fontWeight: 'bold'
                            }}
                            target="_blank"
                        >PDF</a>
                    )}
                </BlobProvider>
            </div>

        </Stack>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '50px',
            width: '100%'
        }}>


            <div
                style={{
                    width: '800px'
                }}
            >

                <div className="_title">Cotation</div>
                <UpdatedTable
                    head={['pax', 'hebergement', 'transport', 'totalCFA', 'totalEuro', '15%', '20%']}
                    rows={outputs}


                />

            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
                width: '50%'
            }}>
                <HotelsTable hotels={hotels} onHotelChanged={onHotelsChanged} />
                <FraisTable frais={frais} onFraisChanged={onFraisChanged} />
                <div>
                    <div className="_title">Liste des cotations</div>
                    <StyledTable
                        rows={cotations}
                        head={["id", "name"]}
                        onOptionClicked={(i) => {
                            console.log(i);
                            cotations.map((c) => {
                                if (c.id === i) {
                                    const content = JSON.parse(c.content);
                                    console.log(content);
                                    setFrais(content.frais);
                                    setHotels(content.hotels);

                                }
                            })
                        }}


                    />
                </div>


            </div>

        </div>
        <SaveDialog show={showSaveDialog} handleClose={() => setShowSaveDialog(false)} onSave={(name) => {

            console.log('saved:', name);
            setShowSaveDialog(false);

            createCotation({
                name,
                content: JSON.stringify({
                    hotels,
                    frais,
                    outputs
                })
            }, (err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);

                notify("La cotation a été ajouté avec succés!");
                dispatch(fetchCotations());

            });
        }} />
    </>
        ;
}




export default Calculator;