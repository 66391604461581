


import { priceFormatterV3 } from '../../settings/themeSettings';
import './DashboardTable.scss';


const DashboardTableII = ({ caption, data, title, showSubtitle = true }) => {



    return <table class='dashboard-table'>
        <caption> {caption} </caption>
        {title && <thead>
            <tr>
                 <th></th>
            {title.map((tl,idx) => <th colSpan={showSubtitle ? 2 : 1} key={idx} style={{textAlign: 'center'}}>{tl}</th>)}
            </tr>
           
        </thead>}
        <tbody>
           {showSubtitle && <tr>
                <td></td>
                {
                    title.map((tl) => <><td style={{textAlign: 'center'}}>Montant</td> <td  style={{textAlign: 'center'}}>Pourcentage</td></>)
                }
    
            </tr>}
            {
                 Object.keys(data).map((objkey, idx) => {
                         console.log({q: data[objkey]});
                        return <tr key={idx}>
                            <td>{objkey}</td>

                            {
                                title.map((tl) => Array.isArray(data[objkey][tl]) ? data[objkey][tl].map((v, key) => <td key={key} style={{ textAlign: isNaN(Number(v)) ? 'left' : 'right' }} >{isNaN(Number(v)) ? v : priceFormatterV3(v)}</td>) : <td style={{ textAlign: isNaN(Number(data[objkey][tl])) ? 'left' : 'right' }}> { priceFormatterV3(data[objkey][tl])}</td>)
                            }

                        </tr>
                    })
                


            }
        </tbody>

    </table>;
}



export default DashboardTableII;