import { useDispatch, useSelector } from 'react-redux';
import './journal.scss';
import { useEffect, useState } from 'react';
import billetsSlice, { fetchBillets } from '../../data/slices/billetsSlice';
import UpdatedTable from '../../components/updated-table/StyledTable';
import SearchIcon from '@mui/icons-material/Search';
import {
    FileDownloadOutlined, FilterAlt
    , MonetizationOn, PaymentTwoTone, PaymentsTwoTone, PersonPinCircle, Refresh, SwipeLeftAlt, SwipeRightAlt, TramSharp
} from "@mui/icons-material";
import { COLORS, jsonToXlsx, priceFormatterV3 } from '../../settings/themeSettings';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { Typography } from '@mui/material';
import JournalPDF from '../../components/JournalPDF/JournalPDF';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import _ from "lodash";
import JournalVentePDF from '../../components/JournalPDF/JournalVentePDF';
import { fetchPaiments } from '../../data/slices/paiementsSlice';
import DashboardTableII from '../../components/DashboardTable/DashboardTableII';
import DashboardTableIII from '../../components/DashboardTable/ReversedTable';


const Caisse = (props) => {

    const { billets, fetchBilletsStatus } = useSelector((state) => state.billets);
    const { paiements, fetchPaiementStatus } = useSelector((state) => state.paiements);

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const {filterBillets}  = billetsSlice.actions;
    const [paymentGroup, setPaymentGroup] = useState({})

    useEffect(() => {
        dispatch(fetchBillets());
        dispatch(fetchPaiments());
        
    }, []);



    useEffect(() => {

        if (!!startDate && !!endDate) {
            dispatch(fetchBillets({ startDate, endDate }));
            dispatch(fetchPaiments({ startDate, endDate }));
        }


    }, [startDate, endDate]);



    useEffect(() => {
        console.log({paiements: _.groupBy(paiements,"methode")});
        setPaymentGroup(_.groupBy(paiements,"methode"));
    }, [paiements]);




    return (fetchBilletsStatus == 'loading') ?
        <div className="loader-container">
            <span className="loader"></span>
        </div>
        : <>



            <div className="header">
                <div className="title">
                    Caisse
                </div>

            </div>

            <div className="table-header">

                <div className="tools">
                    <div className="btn" onClick={() => {
                        setEndDate(null);
                        setStartDate(null);
                        dispatch(fetchBillets());

                    }}>
                        <Refresh style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                    </div>
                    <div className="btn">
                        <span>De</span>
                        <input type="date" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                        <span>à</span>

                        <input type="date" value={endDate} onChange={(e) => {
                            console.log(endDate);
                            setEndDate(e.target.value);
                        }} />
                    </div>

                    <div className="btn" onClick={
                        () => {

                            if (billets && billets.length > 0) {
                                jsonToXlsx(billets, "Journal de vente");
                            }
                        }
                    }>
                        <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Excel</Typography>
                    </div>

                    <div className="btn"
                        onClick={() => {
                            document.getElementById('journalv')?.click();
                        }}
                    >
                        <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <BlobProvider document={<JournalVentePDF data={[...billets].reverse()} heads={['numero', 'nom', 'trajet', 'montantAchat', 'tarif', 'montantEncaisse']} title="Caisse" />}>
                            {({ blob, url }) => (
                                <a href={url}
                                    id='journalv'
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        fontSize: '1em',
                                        color: COLORS.secondary,
                                        fontWeight: 'bold'
                                    }}
                                    target="_blank"
                                >PDF</a>
                            )}
                        </BlobProvider>
                    </div>
                </div>
                <div className="searchbar">


                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon style={{ fill: COLORS.secondary }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Recherche"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') {
                                        dispatch(filterBillets({ nail: e.target.value }));


                                    }
                                }
                            }
                        />
                    </Search>
                </div>
            </div>

            <UpdatedTable rows={[...billets].reverse()} 
            head={['numero','date', 'nom', 'tarif',  'montantEncaisse', 'commentaire']}
            totals={['montantEncaisse', 'tarif']} 
            headSubs={{"montantEncaisse": "Montant Payé", "tarif": "Montant Facturé", "numero": "numero pièces", "nom": "Clients/libellés" }}
            ></UpdatedTable>

            <DashboardTableIII
            
            caption={"Synthèse des paiements"}
            data={paymentGroup}
            total={priceFormatterV3( _.sumBy(paiements, "montant"))}
 
            
            
            />
        </>;







}


export default Caisse;