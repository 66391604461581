




import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, nameFormatter, priceFormatter, priceFormatterV3 } from '../../settings/themeSettings';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingVertical: 30
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 12,
        color: 'black',
        paddingLeft: '35px'
    },
    title: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleRight: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        // height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2
    },
    titleSpan: {
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: 2
    },
    newtitleStyle: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        alignSelf: 'flex-start',
        marginVertical: 2,
        paddingHorizontal: 5,
        paddingVertical: 2,
        backgroundColor: COLORS.gray,
        borderRadius: 2



    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginVertical: 2,
        backgroundColor: COLORS.gray,
        paddingHorizontal: 5,
        paddingVertical: 2
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        borderBottom: '0.5px solid black',
        marginVertical: 2
    }

});






const NJournalPDF = ({ datas, title, heads }) => {

    return <Document>

        <Page size="A4" style={styles.page}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                minHeight: 50,
                padding: 5
            }}>
                <Image
                    style={{
                        height: 45,
                        width: 100
                    }}
                    source={logo} />
            </View>


            {
                datas.map((data, idx) => <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>{title} </Text>
                    <View style={styles.tableHeader}>
                        {
                            heads[idx]?.map((h) => <Text style={{ width: '20%', fontSize: 10 }}>{nameFormatter(h)}</Text>)
                        }


                    </View>


                    {
                        data?.map((d) => <View style={styles.tableRow}>

                            {
                                heads[idx]?.map((h) => <Text style={{ width: '20%', fontSize: 10 }}>
                                 {/montant|prix|tarif|depouillement|marge$|CFA$/.test(h)? priceFormatterV3( d[h]) : d[h]}
                                      </Text>)
                            }


                        </View>)

                    }




                </View>)
            }




        </Page>
    </Document>
}


export default NJournalPDF;