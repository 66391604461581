
import './dashboard.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillets } from '../../data/slices/billetsSlice';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { COLORS, priceFormatterV2, priceFormatter, priceFormatterV3 } from '../../settings/themeSettings';
import DashboardTable from '../../components/DashboardTable/DashboardTable';
import { DASHBOARD_URL } from '../../settings/APISetting';
import { PDFViewer, Page, Document } from '@react-pdf/renderer';
import DashboardTablePDF from '../../components/DashboardTable/DashboardTablePDF';
import DashboardTablePDFII from '../../components/DashboardTable/DashboardTablePDFII';
import DashboardTableII from '../../components/DashboardTable/DashboardTableII';



const Dashboard = (props) => {
    const { billets } = useSelector((state) => state.billets);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [syntheseData, setSyntheseData] = useState([]);
    const [margeByCompanyData, setMargeByCompanyData] = useState([]);
    const [caByCompanyData, setCaByCompanyData] = useState([]);
    const [totalCaBilleterie, settotalCaBilleterie] = useState({
        montantReel: 0,
        montantPrevisionnel: 0
    });

    const [deboursByCompanyData, setDeboursByCompanyData] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [tabIdx, setTabIdx] = useState(0);
    const [tab, setTab] = useState(0);
    const [subTab, setSubTab] = useState(0);
    const [currentYear, setCurrentYear] = useState((new Date()).toISOString().split('-')[0]);
    const [cloture, setCloture] = useState(0);

    const arrayCleaner = (arr) => {
        if (!Array.isArray(arr)) return arr;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] == null) {
                arr[i] = 0;
            } else {
                arr[i] = Number(arr[i]);
            }

        }

        return arr;
    }

    const sumArrays = (a, b) => {

        let c = [];
        if (Array.isArray(a) && Array.isArray(b) && (a.length == b.length)) {

            for (let i = 0; i < a.length; i++) {
                c[i] = Number(a[i]) + Number(b[i]);
            }
        }



        return c.map(priceFormatterV2);


    }


    const getDashBoard = async ({ startDate, endDate, cloture } = {}) => {

        let response = {};
        if (!!startDate && !!endDate && cloture) {
            response = await fetch(`${DASHBOARD_URL}?startDate=${startDate}&endDate=${endDate}&cloture=${cloture}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } else if (!!startDate && !!endDate) {
            response = await fetch(`${DASHBOARD_URL}?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } else if (cloture) {
            response = await fetch(`${DASHBOARD_URL}?cloture=${cloture}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } else {
            response = await fetch(`${DASHBOARD_URL}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        }




        return response.json();
    }

    const billeterieSwitch = (index) => {
        switch (index) {
            case 0: return billeterieByMonth();
            case 1: return billeterieBySemester();
            case 2: return billeterieByTrimester();
        }
    }

    const titleSwitch = (index) => {
        if (tab == 1) {
            switch (index) {
                case 0: return ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août",
                    "Septembre", "Octobre", "Novembre", "Décembre"];
                case 1: return ["Janvier - Juin", "Juillet - Décembre"];
                case 2: return ["Janvier - Mars", "Avril - Juin", "Juillet - Septembre", "Octobre - Décembre"];
            }
        } else {
            return null;
        }

    }


    const tourismeSwitch = (index, paiement, frais) => {

        console.log({ paiement });
        console.log({ frais });


        switch (index) {
            case 0: return {
                ca: arrayCleaner(paiement),
                debours: arrayCleaner(frais),
                get marge() {
                    return this.ca?.map((item, idx) => {
                        return item - this.debours[idx];
                    })
                }
            };
            case 1: return {
                ca: [_.sum(arrayCleaner(paiement?.slice(0, 5))), _.sum(arrayCleaner(paiement?.slice(5, 12)))],
                debours: [_.sum(arrayCleaner(frais?.slice(0, 5))), _.sum(arrayCleaner(frais?.slice(5, 12)))],
                get marge() {
                    return this.ca.map((item, idx) => {
                        return item - this.debours[idx];
                    })
                }
            };

            case 2: return {
                ca: [_.sum(arrayCleaner(paiement?.slice(0, 3))), _.sum(arrayCleaner(paiement?.slice(3, 6))), _.sum(arrayCleaner(paiement?.slice(6, 9))), _.sum(arrayCleaner(paiement?.slice(9, 12)))],
                debours: [_.sum(arrayCleaner(frais?.slice(0, 3))), _.sum(arrayCleaner(frais?.slice(3, 6))), _.sum(arrayCleaner(frais?.slice(6, 9))), _.sum(arrayCleaner(frais?.slice(9, 12)))],
                get marge() {
                    return this.ca.map((item, idx) => {
                        return item - this.debours[idx];
                    });
                }

            };
        }
    }
    const billeterieBySemester = () => {
        const firstSemester = new Date(`${currentYear}-06-30`);
        return {

            "ca": [_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) <= firstSemester) {
                        return b.tarif;
                    }
                    return 0;
                }), _.sumBy(
                    _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                    , (b) => {
                        if ((new Date(b.date)) > firstSemester) {
                            return b.tarif;
                        }
                        return 0;
                    })

            ],
            "debours": [_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) <= firstSemester) {
                        return b.montantAchat;
                    }
                    return 0;
                }
            ), _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) > firstSemester) {
                        return b.montantAchat;
                    }
                    return 0;
                })],
            "marge": [_.sumBy(

                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) <= firstSemester) {
                        return b.marge;
                    }
                    return 0;
                }), _.sumBy(
                    _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                    , (b) => {
                        if ((new Date(b.date)) > firstSemester) {
                            return b.marge;
                        }
                        return 0;
                    })]

        };
    }

    const billeterieByTrimester = () => {
        const ca = [0, 0, 0, 0], debours = [0, 0, 0, 0], marge = [0, 0, 0, 0];
        const months = [
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-12-31`]

        ];

        months.map((month, idx) => {
            ca[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.tarif;
                    }
                    return 0;
                });
            debours[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.montantAchat;
                    }
                    return 0;
                });

            marge[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.marge;
                    }
                    return 0;
                });

        })


        return {

            ca,
            debours,
            marge

        };
    }

    const billeterieByMonth = () => {
        const ca = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const debours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const marge = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const months = [
            [`${currentYear}-01-01`, `${currentYear}-01-31`],
            [`${currentYear}-02-01`, `${currentYear}-02-28`],
            [`${currentYear}-03-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-07-31`],
            [`${currentYear}-08-01`, `${currentYear}-08-31`],
            [`${currentYear}-09-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-10-31`],
            [`${currentYear}-11-01`, `${currentYear}-11-30`],
            [`${currentYear}-12-01`, `${currentYear}-12-31`]

        ];

        months.map((month, idx) => {
            ca[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)
                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.tarif;
                    }
                    return 0;
                });

            debours[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.montantAchat;
                    }
                    return 0;
                });


            marge[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , (b) => {
                    if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                        return b.marge;
                    }
                    return 0;
                });

        })

        return {

            ca,
            debours,
            marge

        };
    }





    // useEffect(() => {
    //     //console.log(billets);
    //     //  dispatch(fetchBillets());


    //     getDashBoard().then((data) => {
    //         console.info(data);
    //         setDashboardData(data);
    //     })
    //         .catch((err) => console.log(err));

    // }, []);

    useEffect(() => {

          let _ca = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , 'tarif');


            settotalCaBilleterie({montantReel: _.sumBy(
                _.filter(billets, (b) =>  (b.etat == 'FLOWN'))
                , 'tarif'),
                montantPrevisionnel: _.sumBy(billets, 'tarif')
             })



            let _debours = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

                , 'montantAchat');

            let _marge = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)
                , (b) => b.marge > 0 ? b.marge : 0);

        setSyntheseData([{
            title: "Chiffre d'affaires",
            value: tab == 0 ? (() => {
                return [priceFormatterV2(_ca), 100]
            })()  : billeterieSwitch(subTab).ca?.map(priceFormatterV2)
        },
        {
            title: "Débours billets d'avions",
            value: tab == 0 ? [priceFormatterV2(_debours), Math.round(_debours/_ca * 100)] : billeterieSwitch(subTab).debours?.map(priceFormatterV2)
        }, {
            title: "Marge",
            value: tab == 0 ? [priceFormatterV2(_marge), Math.round(_marge/_ca * 100)] : billeterieSwitch(subTab).marge?.map(priceFormatterV2)
        }]);


        const totalMarge = _.sumBy(
            _.filter(
                billets
                , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

            , (b) => b.marge > 0 ? b.marge : 0);
        const totalCA = _.sumBy(
            _.filter(
                billets
                , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

            , 'tarif');
        const totalDebours = _.sumBy(
            _.filter(
                billets

                , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

            , 'montantAchat');

        let _margeByCompanyData = [];
        let _deboursByCompanyData = [];
        let _caByCompanyData = [];

        //marge par compagnie 
        let groupedByCompany = _.groupBy(

            _.filter(
                billets

                , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)

            , 'compagnie');
        Object.keys(groupedByCompany).map((key) => {

            _margeByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], (b) => b.marge > 0 ? b.marge : 0)) + " / " + Math.round((_.sumBy(groupedByCompany[key], (b) => b.marge > 0 ? b.marge : 0) / totalMarge) * 100) + ' %').split("/")
            });

            _deboursByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], 'montantAchat')) + " / " + (Math.round((_.sumBy(groupedByCompany[key], 'montantAchat') / totalDebours) * 100)) + ' %').split("/")
            });
            _caByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], 'tarif')) + " / " + (Math.round((_.sumBy(groupedByCompany[key], 'tarif') / totalCA)) * 100) + ' %').split("/")
            });
        });


        setMargeByCompanyData(_margeByCompanyData);
        setDeboursByCompanyData(_deboursByCompanyData);
        setCaByCompanyData(_caByCompanyData);



    }, [billets, tab, subTab, cloture]);

    useEffect(() => {

        if (!!startDate && !!endDate && (cloture != null)) {
            dispatch(fetchBillets({ startDate, endDate }));

            getDashBoard({ startDate, endDate, cloture }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        } else if (!!startDate && !!endDate) {
            dispatch(fetchBillets({ startDate, endDate }));

            getDashBoard({ startDate, endDate }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        } else {
            dispatch(fetchBillets());

            getDashBoard({ cloture }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        }

        console.log({ cloture })


    }, [startDate, endDate, cloture]);

    useEffect(() => {
        setCurrentYear((new Date()).toISOString().split('-')[0]);
        if (tab == 1) {
            dispatch(fetchBillets({ startDate: `${currentYear}-01-01`, endDate: `${currentYear}-12-31` }));

        } else {
            dispatch(fetchBillets());

        }
        getDashBoard({ cloture }).then((data) => {
            console.info(data);
            setDashboardData(data);
        })
            .catch((err) => console.log(err));

        console.log(tab);
    }, [tab]);



    return <div className="dashboard">
        <div className="header">
            <div className="title">
                Tableau de bord
            </div>

        </div>

        <div className="filter section">
            <span className={cloture == 0 ? "title active" : "title"} onClick={() => setCloture(0)}>
                Montant prévisionnel
            </span>
            <span className={cloture == 1 ? "title active" : "title"} onClick={() => {
                setCloture(1);

            }}>
                Montant réel
            </span>


        </div>

        <div className="filter section">
            <span className={tabIdx == 0 ? "title active" : "title"} onClick={() => setTabIdx(0)}>
                Synthèse des activités
            </span>
            <span className={tabIdx == 1 ? "title active" : "title"} onClick={() => setTabIdx(1)}>
                Billetteries
            </span>
            <span className={tabIdx == 2 ? "title active" : "title"} onClick={() => setTabIdx(2)}  >
                Tourisme
            </span>

            <span className={tabIdx == 3 ? "title active" : "title"} onClick={() => setTabIdx(3)} >
                Chiffre d'affaire et marge
            </span>

            <span className={tabIdx == 4 ? "title active" : "title"} onClick={() => setTabIdx(4)} >
                TO/Client
            </span>

        </div>


        <div className="tabs">
            <div className={tab == 0 ? "tab active" : "tab"} onClick={() => setTab(0)}>
                Période
            </div>
            <div className={tab == 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>
                Echelonnement
            </div>
        </div>
        {tab == 0 && <div className="filter">
            <span className="title">
                Choisir Période
            </span>
            <input type="date" value={startDate} onChange={(e) => {
                setStartDate(e.target.value);
            }} />
            <input type="date" value={endDate} onChange={(e) => {
                console.log(endDate);
                setEndDate(e.target.value);
            }} />

            <div className="btn" onClick={() => {
                setEndDate(null);
                setStartDate(null);
                dispatch(fetchBillets());

            }}>
                <Refresh style={{ fill: COLORS.secondary }} />
                <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
            </div>

        </div>
        }
        {
            tab == 1 && <div className="filter section">
                <span className={subTab == 0 ? "title active" : "title"} onClick={() => setSubTab(0)} >
                    Mois
                </span>
                <span className={subTab == 2 ? "title active" : "title"} onClick={() => setSubTab(2)} >
                    Trimestre
                </span>
                <span className={subTab == 1 ? "title active" : "title"} onClick={() => setSubTab(1)} >
                    Semestre
                </span>

            </div>
        }

        <div className="dashboard-content">
            {tabIdx == 0 && <div className="chart-item" >
                <div className="chart-item-title">
                    Analyse globale par activité
                </div>

                <div className="table-container">


                    <div>
                        <DashboardTable caption="Billetterie"
                            data={syntheseData}
                            title={titleSwitch(subTab)}

                        />

                        <DashboardTable
                            title={titleSwitch(subTab)}

                            caption="Tourisme" 
                            data={[
                                {
                                    title: "Chiffre d'affaire",

                                    value: tab == 0 ? [priceFormatterV2(((cloture == 0) ? dashboardData?.totalPaiementTourisme : dashboardData?.totalPaiementTourismeReel) || 0), 100] : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV2),
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? [priceFormatterV2(dashboardData?.totalFraisTourisme || 0) ,
                                        Math.round(
                                            dashboardData?.totalFraisTourisme 
                                            / dashboardData?.totalPaiementTourisme
                                            * 100
                                        )

                                    ] : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).debours?.map(priceFormatterV2),
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? [(((cloture == 0) ? dashboardData?.totalPaiementTourisme : dashboardData?.totalPaiementTourismeReel)  || 0) - (dashboardData?.totalFraisTourisme || 0),
                                        Math.round(
                                            (((cloture == 0) ? dashboardData?.totalPaiementTourisme : dashboardData?.totalPaiementTourismeReel) 
                                            - dashboardData?.totalFraisTourisme) 
                                            / dashboardData?.totalPaiementTourisme
                                            * 100
                                        )

                                    ] : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).marge?.map(priceFormatterV2)

                                }


                            ]} />

                            <DashboardTableII 
                                caption={"Comparatif réel vs prévisionnel"}
                                title = {[ "Montant Prévisionnel","Montant Réel", "Ecart"]}

                                showSubtitle = {false}
                                data = {{
                                    "Tourisme" : {
                                       "Montant Prévisionnel": dashboardData?.totalPaiementTourisme,
                                        "Montant Réel" : dashboardData?.totalPaiementTourismeReel,
                                        "Ecart" : Math.abs(dashboardData?.totalPaiementTourisme - (dashboardData?.totalPaiementTourismeReel || 0))

                                    },
                                    "Billeterie": {
                                        "Montant Prévisionnel": totalCaBilleterie.montantPrevisionnel,
                                        "Montant Réel" : totalCaBilleterie.montantReel,
                                        "Ecart" : Math.abs(totalCaBilleterie.montantPrevisionnel - totalCaBilleterie.montantReel)
                                    }
                                }}                               

                            
                            />

                    </div>





                </div>


            </div>}
            {tabIdx == 1 && <div className="chart-item">
                <div className="chart-item-title">
                    Billetteries
                </div>
                {/* <div className="chart-item-content">
                    <RadarChart identifier="radarchartdiv" data={groupedByCompany} />
                </div> */}
                <div className="table-container">
                    <div>
                        <DashboardTable title={titleSwitch(subTab)}
                            caption="Billetterie" data={syntheseData} />

                        <DashboardTableII

                            caption="Synthèse billeterie par compagnie"
                            title={["Chiffre d'affaire", "Total Débours", "Marge"]}

                            data={(() => {
                                let data = {};
                                let unformatedData = caByCompanyData.concat(deboursByCompanyData).concat(margeByCompanyData);
                                unformatedData = _.groupBy(unformatedData, "title");
                                console.log({ unformatedData });
                                let unformatedDatakeys = Object.keys(unformatedData)
                                unformatedDatakeys.forEach((key) => {
                                    if (!key) return;
                                    console.log(unformatedData[key]);
                                    data[key] = {
                                        "Chiffre d'affaire": unformatedData[key][0].value,
                                        "Total Débours": unformatedData[key][1].value,
                                        "Marge": unformatedData[key][2].value,
                                    }
                                })

                                console.log({ billets })

                                data["TOTAL"] = {
                                    "Chiffre d'affaire": [priceFormatterV3(_.sumBy(billets, 'tarif')), '100%'],
                                    "Total Débours": [priceFormatterV3(_.sumBy(billets, 'montantAchat')), '100%'],
                                    "Marge": [priceFormatterV3(_.sumBy(billets, 'marge')), '100%']
                                }

                                return data;

                            })()

                            }

                        />

                        {/* <DashboardTable caption="CA par Compagnie" data={caByCompanyData} />
                        <DashboardTable caption="Debours par Compagnie" data={deboursByCompanyData} />
                        <DashboardTable caption="Marge par Compagnie" data={margeByCompanyData} /> */}
                    </div>

                </div>
            </div>}
            {tabIdx == 2 && <div className="chart-item">
                <div className="chart-item-title">
                    Analyse tourisme par prestation
                </div>

                <div className="table-container">
                    <div>


                        <DashboardTableII
                            caption="Synthèse par prestation"
                            title={["Chiffre d'affaire", "Total Débours", "Marge"]}
                            data={{
                                "circuit": {
                                    "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/"),
                                    "Total Débours": (priceFormatterV3(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/"),
                                    "Marge": (priceFormatterV3(((dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0))) + " / " + Math.round(
                                        ((dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme)
                                        * 100) + "%").split("/"),
                                },
                                "sejour": {
                                    "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme
                                    ) * 100 + "%").split("/"),
                                    "Total Débours": (priceFormatterV3(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/"),
                                    "Marge": (priceFormatterV3(((dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisSejour || 0))) + " / " + Math.round(
                                        ((dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisSejour || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                    ) + "%").split("/"),
                                },
                                "Excursion": {
                                    "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/"),
                                    "Total Débours": (priceFormatterV3(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/"),
                                    "Marge": (priceFormatterV3(((dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0))) + " /" + Math.round(
                                        ((dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                    ) + "%").split("/"),
                                },
                                "Transfert": {
                                    "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/"),
                                    "Total Débours": (priceFormatterV3(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/"),
                                    "Marge": (priceFormatterV3(((dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0))) + " / " + Math.round(
                                        ((dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                    ) + "%").split("/")
                                },
                                "Total": {
                                    "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementTourisme || 0) + " / 100%").split("/"),
                                    "Total Débours": (priceFormatterV3(dashboardData?.totalFraisTourisme || 0) + " / 100%").split("/"),
                                    "Marge": (priceFormatterV3(((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) + " / 100%").split("/")
                                }

                            }
                            }



                        />

                        {tab > 0 && <> <DashboardTable
                            title={titleSwitch(subTab)}

                            caption="Tourisme" data={[
                                {
                                    title: "Chiffre d'affaire",

                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV2),
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours?.map(priceFormatterV2),
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge?.map(priceFormatterV2)

                                }


                            ]} />
                            <DashboardTable caption="Circuit"
                                title={titleSwitch(subTab)}


                                data={[
                                    {
                                        title: "Chiffre d'affaire",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                            dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).ca?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Total Débours",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                            dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).debours?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Marge",
                                        value: tab == 0 ? (dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).marge?.map(priceFormatterV2)
                                    }


                                ]} />

                            <DashboardTable caption="Sejour" data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).ca?.map(priceFormatterV2)
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).debours?.map(priceFormatterV2)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).marge?.map(priceFormatterV2)
                                }


                            ]} />


                            <DashboardTable

                                title={titleSwitch(subTab)}

                                caption="Excursion" data={[
                                    {
                                        title: "Chiffre d'affaire",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                            dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).ca?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Total Débours",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                            dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).debours?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Marge",
                                        value: tab == 0 ? (dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).marge?.map(priceFormatterV2)
                                    }


                                ]} />



                            <DashboardTable
                                title={titleSwitch(subTab)}

                                caption="Transfert" data={[
                                    {
                                        title: "Chiffre d'affaire",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                            dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).ca?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Total Débours",
                                        value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                            dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                        ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).debours?.map(priceFormatterV2)
                                    },
                                    {
                                        title: "Marge",
                                        value: tab == 0 ? (dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).marge?.map(priceFormatterV2)
                                    }


                                ]} />
                        </>}
                    </div>




                </div>


            </div>}
            {tabIdx == 3 && <div className="chart-item">
                <div className="chart-item-title">
                    Analyse Chiffre d'affaires et marges par activité

                </div>

                <div className="table-container">

                    <div>
                        <DashboardTableII 
                        caption={"Analyse Chiffre d'affaires"}
                        title={[ "Chiffre d'affaire", "Débours", "Marge"]}
                        data={{
                            "Billeterie": {
                                 "Chiffre d'affaire": (priceFormatterV2(_.sumBy(billets, 'tarif') || 0) + " / " + Math.round((Number(_.sumBy(billets, 'tarif') || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/"),
                                "Débours": (priceFormatterV2(_.sumBy(billets, 'montantAchat')) + " / " + Math.round((Number(_.sumBy(billets, 'montantAchat')) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/"),
                                "Marge": (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (priceFormatterV2(_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                    + " / " +
                                    (Math.round(
                                        ((_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                         /
                                          (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) +
                                           ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))
                                        
                                        )) * 100
                                    
                                    ) + '%')).split("/")
                                : '100%'

                            },
                            "Tourisme": {
                                "Chiffre d'affaire": (priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalPaiementTourisme || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/"),
                                "Débours": (priceFormatterV2(dashboardData?.totalFraisTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalFraisTourisme || 0) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/"),
                                "Marge": (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) + " / " +
                                    Math.round(
                                        (((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))
                                         / 
                                         (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)))) * 100
                                    ) + '%').split("/")
                                : '0%'
                            },
                            "Total": {
                                "Chiffre d'affaire": [priceFormatterV2(Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0)), "100%"],
                                "Débours": [priceFormatterV2(Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat'))), "100%"],
                                "Marge": [(() => {
                                    return ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) > 0 ?
                                       (  _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)))
                                         : _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0)
                                     
                                 })(), "100%"]
                            }
                        }}
                        
                        
                        />






                        <DashboardTable

                            title={titleSwitch(subTab)}

                            caption="Totaux" data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? priceFormatterV2(Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0)) :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca, billeterieSwitch(subTab).ca)
                                },
                                {
                                    title: "Débours",
                                    value: tab == 0 ?
                                        priceFormatterV2(Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat'))) :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours, billeterieSwitch(subTab).debours)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (() => {
                                       return ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) > 0 ?
                                          (  _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + ((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)))
                                            : _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0)
                                        
                                    })() :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge, billeterieSwitch(subTab).marge)


                                }


                            ]} />

                    </div>





                </div>



            </div>}

            {
                tabIdx == 4 && <div className="chart-item">
                    <div className="chart-item-title">
                        TO/Client
                    </div>
                    <div className="table-container">
                        <div>
                            {tab == 0 && <DashboardTable
                                title={['Débours', 'CA', 'Marge', '% Marge']}

                                caption="Tourisme par TO/Client" data={
                                    (!!dashboardData.fraisByClient) ?
                                        Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                            .map((title) => ({
                                                title,
                                                value: [...(() => {
                                                    let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                                    return [_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0),
                                                    _.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0),
                                                    (_.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0) || 0) - (_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0) || 0),
                                                    (Math.round(((_.sumBy(r, (i) => {

                                                        return i.paiement ? Number(i.paiement) : 0;

                                                    }) || 0) - (_.sumBy(r, (i) => {
                                                        return i.frais ? Number(i.frais) : 0;


                                                    }) || 0)) /

                                                        ((dashboardData.totalPaiementTourisme || 0) - (dashboardData.totalFraisTourisme || 0))
                                                    ) * 100) + '%'

                                                    ]
                                                })()


                                                ].map((item) => {
                                                    return isNaN(item) ? item : priceFormatterV2(item);
                                                })
                                            }))

                                        : []
                                } />}

                            {tab == 0 &&  <DashboardTableII



                        caption="Synthèse CA par clients"
                        title={["circuit", "excursion", "sejour", "transfert"]}
                        data={(() => {
                            console.log("Synthèse CA par clients");
                            let data = {}
                            if (dashboardData.fraisByClient) {
                                data = _.groupBy(dashboardData.paiementByClient, 'libelle')
                                console.log({ dataByKey: data });
                                let clients = Object.keys(data)
                                console.log(clients);
                                let totalCA = _.sumBy(dashboardData.paiementByClient, (e) => Number(e.paiement))
                                clients.forEach(client => {

                                    console.log({ client: data[client] });


                                    let circuitPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'circuit'),

                                        'paiement'
                                    );
                                    let excurionPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'excursion'),
                                        'paiement'
                                    );

                                    let sejourPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'sejour'),
                                        'paiement'
                                    );
                                    let transfertPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'transfert'),
                                        'paiement'
                                    );

                                    data[client] = {
                                        "circuit": [priceFormatterV3(circuitPaiement),
                                        Math.round((circuitPaiement / totalCA) * 100) + '%'
                                        ],
                                        "excursion": [priceFormatterV3(excurionPaiement),
                                        Math.round((excurionPaiement / totalCA) * 100) + '%'
                                        ],
                                        "sejour": [priceFormatterV3(sejourPaiement),
                                        Math.round((sejourPaiement / totalCA) * 100) + '%'

                                        ],
                                        "transfert": [priceFormatterV3(transfertPaiement),
                                        Math.round((transfertPaiement / totalCA) * 100) + '%'

                                        ]
                                    }
                                })
                            }
                            data["TOTAL"] = {
                                "circuit": [
                                    priceFormatterV3(dashboardData.totalPaiementCircuit)
                                    , "100%"],
                                "excursion": [
                                    priceFormatterV3(dashboardData.totalPaiementExcursion),
                                    "100%"],
                                "sejour": [
                                    priceFormatterV3(dashboardData.totalPaiementSejour)
                                    , "100%"],
                                "transfert": [priceFormatterV3(dashboardData.totalPaiementTransfert),
                                    "100%"]
                            }


                            return data
                        })()
                        }

                    />}

                        </div>
                    </div>
                </div>
            }
        </div>


        <PDFViewer
            style={{
                width: "100%",
                height: "800px"
            }}
        >
            <Document>
                <Page size="A4" style={{
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    paddingVertical: 30,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingHorizontal: 10,
                    gap: 10
                }}
                    orientation='landscape'>


                    <DashboardTablePDFII
                        caption="Synthèse par prestation"
                        title={["Chiffre d'affaire", "Total Débours", "Marge"]}
                        data={{
                            "circuit": {
                                "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/"),
                                "Total Débours": (priceFormatterV3(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/"),
                                "Marge": (priceFormatterV3(((dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0))) + " / " + Math.round(
                                    ((dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme)
                                    * 100) + "%").split("/"),
                            },
                            "sejour": {
                                "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme
                                ) * 100 + "%").split("/"),
                                "Total Débours": (priceFormatterV3(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/"),
                                "Marge": (priceFormatterV3(((dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisSejour || 0))) + " / " + Math.round(
                                    ((dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisSejour || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                ) + "%").split("/"),
                            },
                            "Excursion": {
                                "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/"),
                                "Total Débours": (priceFormatterV3(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/"),
                                "Marge": (priceFormatterV3(((dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0))) + " /" + Math.round(
                                    ((dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                ) + "%").split("/"),
                            },
                            "Transfert": {
                                "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/"),
                                "Total Débours": (priceFormatterV3(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/"),
                                "Marge": (priceFormatterV3(((dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0))) + " / " + Math.round(
                                    ((dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0)) / (dashboardData?.totalPaiementTourisme - dashboardData?.totalFraisTourisme) * 100
                                ) + "%").split("/")
                            },
                            "Total": {
                                "Chiffre d'affaire": (priceFormatterV3(dashboardData?.totalPaiementTourisme || 0) + " / 100%").split("/"),
                                "Total Débours": (priceFormatterV3(dashboardData?.totalFraisTourisme || 0) + " / 100%").split("/"),
                                "Marge": (priceFormatterV3(((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) + " / 100%").split("/")
                            }

                        }
                        }



                    />
                </Page>
                <Page
                    size="A4" style={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingHorizontal: 10,
                        gap: 4
                    }}
                    orientation='landscape'
                    wrap={false}

                >

                    <DashboardTablePDFII



                        caption="Synthèse CA par clients"
                        title={["circuit", "excursion", "sejour", "transfert"]}
                        data={(() => {
                            console.log("Synthèse CA par clients");
                            let data = {}
                            if (dashboardData.fraisByClient) {
                                data = _.groupBy(dashboardData.paiementByClient, 'libelle')
                                console.log({ dataByKey: data });
                                let clients = Object.keys(data)
                                console.log(clients);
                                let totalCA = _.sumBy(dashboardData.paiementByClient, (e) => Number(e.paiement))
                                clients.forEach(client => {

                                    console.log({ client: data[client] });


                                    let circuitPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'circuit'),

                                        'paiement'
                                    );
                                    let excurionPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'excursion'),
                                        'paiement'
                                    );

                                    let sejourPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'sejour'),
                                        'paiement'
                                    );
                                    let transfertPaiement = _.sumBy(
                                        data[client].filter((el) => el.type === 'transfert'),
                                        'paiement'
                                    );

                                    data[client] = {
                                        "circuit": [priceFormatterV3(circuitPaiement),
                                        Math.round((circuitPaiement / totalCA) * 100) + '%'
                                        ],
                                        "excursion": [priceFormatterV3(excurionPaiement),
                                        Math.round((excurionPaiement / totalCA) * 100) + '%'
                                        ],
                                        "sejour": [priceFormatterV3(sejourPaiement),
                                        Math.round((sejourPaiement / totalCA) * 100) + '%'

                                        ],
                                        "transfert": [priceFormatterV3(transfertPaiement),
                                        Math.round((transfertPaiement / totalCA) * 100) + '%'

                                        ]
                                    }
                                })
                            }
                            data["TOTAL"] = {
                                "circuit": [
                                    priceFormatterV3(dashboardData.totalPaiementCircuit)
                                    , "100%"],
                                "excursion": [
                                    priceFormatterV3(dashboardData.totalPaiementExcursion),
                                    "100%"],
                                "sejour": [
                                    priceFormatterV3(dashboardData.totalPaiementSejour)
                                    , "100%"],
                                "transfert": [priceFormatterV3(dashboardData.totalPaiementTransfert),
                                    "100%"]
                            }


                            return data
                        })()
                        }

                    />


                    <DashboardTablePDFII



                        caption="Synthèse Débours par clients"
                        title={["circuit", "excursion", "sejour", "transfert"]}
                        data={(() => {
                            let data = {}
                            if (dashboardData.fraisByClient) {
                                data = _.groupBy(dashboardData.fraisByClient, 'libelle')
                                console.log({ dataByKey: data });
                                let clients = Object.keys(data)
                                console.log(clients);
                                let totalDebours = _.sumBy(dashboardData.fraisByClient, (e) => Number(e.frais))
                                clients.forEach(client => {

                                    console.log({ client: data[client] });


                                    let circuitFrais = _.sumBy(
                                        data[client].filter((el) => el.type === 'circuit'),

                                        'frais'
                                    );
                                    let excurionFrais = _.sumBy(
                                        data[client].filter((el) => el.type === 'excursion'),
                                        'frais'
                                    );

                                    let sejourFrais = _.sumBy(
                                        data[client].filter((el) => el.type === 'sejour'),
                                        'frais'
                                    );
                                    let transfertFrais = _.sumBy(
                                        data[client].filter((el) => el.type === 'transfert'),
                                        'frais'
                                    );

                                    data[client] = {
                                        "circuit": [priceFormatterV3(circuitFrais),
                                        (Math.round((circuitFrais / totalDebours) * 100) || 0) + '%'
                                        ],
                                        "excursion": [priceFormatterV3(excurionFrais),
                                        (Math.round((excurionFrais / totalDebours) * 100) || 0) + '%'
                                        ],
                                        "sejour": [priceFormatterV3(sejourFrais),
                                        (Math.round((sejourFrais / totalDebours) * 100) || 0) + '%'

                                        ],
                                        "transfert": [priceFormatterV3(transfertFrais),
                                        (Math.round((transfertFrais / totalDebours) || 0) * 100) + '%'

                                        ]
                                    }
                                })
                            }

                            data["TOTAL"] = {
                                "circuit": [
                                    priceFormatterV3(dashboardData.totalFraisCircuit)
                                    , "100%"],
                                "excursion": [
                                    priceFormatterV3(dashboardData.totalFraisExcursion),
                                    "100%"],
                                "sejour": [
                                    priceFormatterV3(dashboardData.totalFraisSejour)
                                    , "100%"],
                                "transfert": [priceFormatterV3(dashboardData.totalFraisTransfert),
                                    "100%"]
                            }

                            return data
                        })()
                        }

                    />

                </Page>

                <Page
                    size="A4" style={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingHorizontal: 10,
                        gap: 4
                    }}
                    orientation='landscape'
                    wrap={false}

                >

                    <DashboardTablePDFII

                        caption="Synthèse billeterie par compagnie"
                        title={["Chiffre d'affaire", "Total Débours", "Marge"]}

                        data={(() => {
                            let data = {};
                            let unformatedData = caByCompanyData.concat(deboursByCompanyData).concat(margeByCompanyData);
                            unformatedData = _.groupBy(unformatedData, "title");
                            console.log({ unformatedData });
                            let unformatedDatakeys = Object.keys(unformatedData)
                            unformatedDatakeys.forEach((key) => {
                                if (!key) return;
                                console.log(unformatedData[key]);
                                data[key] = {
                                    "Chiffre d'affaire": unformatedData[key][0].value,
                                    "Total Débours": unformatedData[key][1].value,
                                    "Marge": unformatedData[key][2].value,
                                }
                            })

                            console.log({ billets })

                            data["TOTAL"] = {
                                "Chiffre d'affaire": [priceFormatterV3(_.sumBy(billets, 'tarif')), '100%'],
                                "Total Débours": [priceFormatterV3(_.sumBy(billets, 'montantAchat')), '100%'],
                                "Marge": [priceFormatterV3(_.sumBy(billets, 'marge')), '100%']
                            }

                            return data;

                        })()

                        }

                    />

                </Page>

                <Page size="A4" style={{
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    paddingVertical: 30,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingHorizontal: 10,
                    gap: 10
                }}

                    orientation={tab == 1 ? 'landscape' : 'portrait'}
                    wrap={false}
                >




                    <DashboardTablePDF
                        title={titleSwitch(subTab)}

                        caption="Tourisme" 
                        data={[
                            {
                                title: "Chiffre d'affaire",

                                value: tab == 0 ? [priceFormatterV3(dashboardData?.totalPaiementTourisme || 0), 100] : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV3),
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? [priceFormatterV3(dashboardData?.totalFraisTourisme || 0) ,
                                    Math.round(
                                        dashboardData?.totalFraisTourisme 
                                        / dashboardData?.totalPaiementTourisme
                                        * 100
                                    )

                                ] : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).debours?.map(priceFormatterV3),
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? [(dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0),
                                    Math.round(
                                        (dashboardData?.totalPaiementTourisme 
                                        - dashboardData?.totalFraisTourisme) 
                                        / dashboardData?.totalPaiementTourisme
                                        * 100
                                    )

                                ] : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).marge?.map(priceFormatterV3)

                            }


                        ]}
                        />

                    <DashboardTablePDF caption="Billetterie"
                        data={syntheseData}
                        title={titleSwitch(subTab)}

                    />

                    {/* <DashboardTablePDF caption="CA par Compagnie" data={caByCompanyData} />
                    <DashboardTablePDF caption="Debours par Compagnie" data={deboursByCompanyData} />
                    <DashboardTablePDF caption="Marge par Compagnie" data={margeByCompanyData} /> */}




                    <DashboardTablePDF caption="Circuit"
                        title={titleSwitch(subTab)}


                        data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).ca?.map(priceFormatterV3)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).debours?.map(priceFormatterV3)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).marge?.map(priceFormatterV3)
                            }


                        ]} />

                    <DashboardTablePDF caption="Sejour" data={[
                        {
                            title: "Chiffre d'affaire",
                            value: tab == 0 ? (priceFormatterV3(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme * 100
                            ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).ca?.map(priceFormatterV3)
                        },
                        {
                            title: "Total Débours",
                            value: tab == 0 ? (priceFormatterV3(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                            ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).debours?.map(priceFormatterV3)
                        },
                        {
                            title: "Marge",
                            value: tab == 0 ? (dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).marge?.map(priceFormatterV3)
                        }


                    ]} />


                    <DashboardTablePDF

                        title={titleSwitch(subTab)}

                        caption="Excursion" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).ca?.map(priceFormatterV3)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).debours?.map(priceFormatterV3)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).marge?.map(priceFormatterV3)
                            }


                        ]} />



                    <DashboardTablePDF
                        title={titleSwitch(subTab)}

                        caption="Transfert" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).ca?.map(priceFormatterV3)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV3(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).debours?.map(priceFormatterV3)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).marge?.map(priceFormatterV3)
                            }


                        ]} />

                    <DashboardTablePDF caption="Pourcentages CA" data={[
                        {
                            title: "Chiffre d'affaire Billetterie",
                            value: (priceFormatterV3(_.sumBy(billets, 'tarif') || 0) + " / " + Math.round((Number(_.sumBy(billets, 'tarif') || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")
                        },
                        {
                            title: "Chiffre d'affaire Tourisme",
                            value: (priceFormatterV3(dashboardData?.totalPaiementTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalPaiementTourisme || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")

                        },



                    ]} />


                    <DashboardTablePDF caption="Pourcentages Débours" data={[
                        {
                            title: "Débours Billetterie",
                            value: (priceFormatterV3(_.sumBy(billets, 'montantAchat')) + " / " + Math.round((Number(_.sumBy(billets, 'montantAchat')) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")
                        },
                        {
                            title: "Débours Tourisme",
                            value: (priceFormatterV3(dashboardData?.totalFraisTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalFraisTourisme || 0) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")

                        }

                    ]} />

                    <DashboardTablePDF caption="Pourcentages Marge" data={[
                        {

                            title: "Marge Billetterie",
                            value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (priceFormatterV3(_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                    + " / " +
                                    (Math.round(((_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%')).split("/")
                                : '100%'
                        }
                        ,
                        {


                            title: "Marge Tourisme",
                            value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) + " / " +
                                    Math.round((((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%').split("/")
                                : '0%'



                        }


                    ]} />


                    <DashboardTablePDF

                        title={titleSwitch(subTab)}

                        caption="Totaux" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? priceFormatterV3(Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0)) :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca, billeterieSwitch(subTab).ca)
                            },
                            {
                                title: "Débours",
                                value: tab == 0 ?
                                    priceFormatterV3(Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat'))) :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours, billeterieSwitch(subTab).debours)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (() => {
                                    priceFormatterV3((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                        _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)
                                        : _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                })() :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge, billeterieSwitch(subTab).marge)


                            }


                        ]} />

                    {/* <DashboardTablePDF
                        title={['Débours', 'CA', 'Marge', '% Marge']}

                        caption="Tourisme par TO/Client" data={
                            (!!dashboardData.fraisByClient) ?
                                Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                    .map((title) => ({
                                        title,
                                        value: [...(() => {
                                            let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                            return [_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0),
                                            _.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0),
                                            (_.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0) || 0) - (_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0) || 0),
                                            (Math.round(((_.sumBy(r, (i) => {

                                                return i.paiement ? Number(i.paiement) : 0;

                                            }) || 0) - (_.sumBy(r, (i) => {
                                                return i.frais ? Number(i.frais) : 0;


                                            }) || 0)) /

                                                ((dashboardData.totalPaiementTourisme || 0) - (dashboardData.totalFraisTourisme || 0))
                                            ) * 100) + '%'

                                            ]
                                        })()


                                        ].map((item) => {
                                            return isNaN(item) ? item : priceFormatterV3(item);
                                        })
                                    }))

                                : []
                        } /> */}

                    {/* {["circuit", "sejour", "transfert", "excursion"].map((dtype, key) => <DashboardTablePDF key={key}
                        title={['CA', '% CA / CA TOTAL']}

                        caption={"Tourisme par TO/Client pour " + dtype} data={
                            (!!dashboardData.fraisByClient) ?
                                Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                    .map((title) => ({
                                        title,
                                        value: [...(() => {
                                            let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                            return [

                                                _.sumBy(r, (i) => {

                                                    if (i.type == dtype) {
                                                        return i.paiement ? Number(i.paiement) : 0;
                                                    } return 0;
                                                }),


                                                (Math.round(((_.sumBy(r, (i) => {

                                                    if (i.type == dtype) {
                                                        return i.paiement ? Number(i.paiement) : 0;
                                                    } return 0;
                                                }) || 0)) /

                                                    ((dashboardData.totalPaiementTourisme || 0))
                                                ) * 100) + '%'
                                            ].map((item) => {
                                                return !(new RegExp("%")).test(item) ? priceFormatterV3(item) : item;
                                            })
                                        })()


                                        ]
                                    }))

                                : []
                        } />)
                    } */}
                </Page>
            </Document>
        </PDFViewer>

    </div>

}


export default Dashboard;