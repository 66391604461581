
import { createTheme } from '@mui/material';
import alegreya from '../fonts/Alegreya/alegreya_regular.ttf'
import { read, utils, writeFile } from 'xlsx';


class FrenchNumbersToWords
{
    french = 'fr'
    number = 0
    numberParts = []
    result = {
        parts: [],
        fullText: ''
    }
    units = {
        0: "zéro",
        1: "un",
        2: "deux",
        3: "trois",
        4: "quatre",
        5: "cinq",
        6: "six",
        7: "sept",
        8: "huit",
        9: "neuf",
        10: "dix",
        11: "onze",
        12: "douze",
        13: "treize",
        14: "quatorze",
        15: "quinze",
        16: "seize"
    }
    tens = {
        be: {
            10: "dix",
            20: "vingt",
            30: "trente",
            40: "quarante",
            50: "cinquante",
            60: "soixante",
            70: "septante",
            80: "huitante",
            90: "nonante"
        },
        fr: {
            10: "dix",
            20: "vingt",
            30: "trente",
            40: "quarante",
            50: "cinquante",
            60: "soixante",
            70: "soixante-dix",
            80: "quatre-vingt",
            90: "quatre-vingt-dix"
        }
    }
    // The units that we use depending on digits count in the number
    groups = [
        {
            single: '',
            plural: ''
        },
        {
            single: 'mille',
            plural: 'mille'
        },
        {
            single: 'million',
            plural: 'millions'
        }
    ]

    // We need the number and to define which version of French we are using
    constructor(number = 0, french = 'fr') {
        if (Object.keys(this.tens).indexOf(french) > -1) {
            this.french = french
            this.number = parseInt(number)
            this.numberAsWord = this.splitNumberPerLength()
        }
    }

    splitNumberPerLength() {
        // we will split the number to groups
        // each group has three digits. Ex: 12345 will have two groups: 12 (for thousands) and 345
        this.numberParts = this.number.toLocaleString("en").split(',')
        let n, full
        for (let j = 0; j < this.numberParts.length; j++) {
            
            n = parseInt(this.numberParts[j])
            full = {
                number: n,
                text: n > 1 ? this.hundred(n) : '',
                unit: this.getGroupNameByIndex(j)
            }
            this.result.parts.push(full)
        }
        this.result.fullText = this.generateFullText()
        return this.result
    }

    generateFullText() {
        let res = []
        for (var i = 0; i < this.result.parts.length; i++) {
            let txt = this.result.parts[i].text
            if (this.result.parts[i].unit) {
                if (txt.length) {
                    txt += '-'
                }
                txt += this.result.parts[i].unit
            }
            if (txt.length) {
                res.push(txt)
            }
        }
        return res.join(' ')
    }
    
    /**
     * Due to the rules of French language, we convert the hundreds part and the tens part seperately
     * @param  {[number]} n the number we want to convert to a word
     * @return {[string]}    number being converted to a word
     */
    twoDigitsConverter(n = null) {
        const num = (n != null) ? n : this.number
        let result = ''
        if (num == '' && typeof num != 'number') {
            return ''
        }
        if (num % 10 === 0 && num > 0) {
            // check in tens
            result = this.tens[this.french][num]
            // apply the plural format to 80
            if (num == 80) {
                result += 's'
            }
            return result
        } else if (this.units[num]) {
            return this.units[num]
        }
        // get the parts of the number, ex: 58 will be an array of [5, 8]
        const numberParts = num.toString().split('')
        // get the number word from tens array, taking the french type in mind
        result = this.tens[this.french][`${numberParts[0]}0`] + '-'
        if (numberParts[1] == 1 && (numberParts[0] < 7)) {
            // if the number is less than 70
            result += 'et-' + this.units[numberParts[1]]
        } else if ((numberParts[0] < 7) || numberParts[0] == 8) {
            // in case the its is les than 70 or exactly 80
            result += this.units[numberParts[1]]
        } else if ((numberParts[0] == 7) || (numberParts[0] == 9)) {
            // in case of 70 and 90, we make a shift by -10 to the tens rank and shift of +10 to unit rank
            result = this.tens[this.french][`${parseInt(numberParts[0]) - 1}0`] + '-'
            if ((numberParts[0] == 7) && (numberParts[1] == 1)) {
                // 71
                result += 'et-'
            }
            result += this.twoDigitsConverter(parseInt(numberParts[1]) + 10)
        }
        return result
    }

    /**
     * Due to the rules of French language, we convert the hundreds part and the tens part seperately
     * @param  {[number]} n the number we want to convert to a word
     * @return {[string]}    number being converted to a word
     */
    hundred(n = null) {
        let result = ''
        const num = n != null ? n : this.number
        // calculate how many hundreds do we have in this number
        const hundreds = Math.floor(num / 100)
        // calculate the tens part of the number
        const rest = num % 100
        if (hundreds == 0) {
            // ex: we are converting the 12 in 12345
            // no need to worry about the cent unit, just convert the 12
            result = this.twoDigitsConverter(rest)
        } else {
            // here, we are converting a group of 3 digits
            result = 'cent'
            if (hundreds > 1) {
                result = this.twoDigitsConverter(hundreds) + '-' + result
            }

            // get the rest (in case we have it)
            if (rest > 0) {
                const restAsWord = this.twoDigitsConverter(rest)
                if (restAsWord) {
                    if (rest == 1) {
                        result += '-et'
                    }
                    result += '-' + restAsWord
                }
            }
        }
        return result
    }
    /**
     * Due to the rules of French language, we convert the hundreds part and the tens part seperately
     * @param  {[number]} groupIndex which group are we converting so we know the unit (nothing, mille, million etc)
     * @return {[string]}    unit of a number (nothing, mille, million etc)
     */
    getGroupNameByIndex(groupIndex) {
        // assume we have a number that is larger than 1 and has unit 
        let suffix = 'plural'
        if (parseInt(this.numberParts) == 1 ) {
            // remove the unit if it is 1
            suffix = 'single'
        } else {
            // remove the unit if the unit is not the last word (other groups has a value greater than 0)
            for (let x = groupIndex + 1; x < this.numberParts.length; x++) {
                if (parseInt(this.numberParts[x]) > 0) {
                    suffix = 'single'
                }
            }
        }
        /*
        get the proper unit, switch the order of array because units are ordered in ASC 
        but we are converting the number groups in DESC order
        */
        let g = this.groups[this.numberParts.length - (groupIndex + 1)][suffix]
        return g
    }
}


export const COLORS = {
  primary: "#FFBA00",
  secondary: "#074E71",
  tertiary: "#857F92",
  gray: "#DEE2E6"
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Alegreya',
  },
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    tertiary: {
      main: COLORS.tertiary
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Alegreya';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Alegreya'), url(${alegreya}) format('tff');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
    },
  }
});
export const priceFormatter = (price) => {
  return (new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).formatToParts(
    isNaN(price) ? 0 : price))
    .map((part) => {
      if (part.type == 'currency') {
        part.value = "FCFA";
      }
      if (!(part.type == 'group' || part.type == "literal" || part.type == "")) {
        return part.value;
      }
    }).join(" ");
}
export const priceFormatterV2 = (price) => {
  return price;
 

}

export const priceFormatterV3 = (price) => {
  return (new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).formatToParts(
    isNaN(price) ? 0 : price))
    .map((part) => {
      if (part.type == 'currency') {
        part.value = "";
      }
      if (!(part.type == 'group' || part.type == "literal" || part.type == "")) {
        return part.value;
      }
    }).join(" ");

}


export const nameFormatter = (name) => {
  const underscoreRegex = new RegExp("_");
  const numeroRegex = new RegExp("numero");
  return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
}


export const sortByProp = (a, b, prop) => {
  if (!a[prop] || !b[prop]) return 0;
  if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
    return -1;
  }
  if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
    return 1;
  }
  return 0;
}

const isValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (!isNaN(date.getTime())) {

      return true
    } else {
      return false;
    }
  } else {

    return false;
  }
}


export const dateFormatter = (str) => {
  if (!str) return;
  const d = new Date(str);


  return isValidDate(d) ? new Intl.DateTimeFormat('fr-FR', { locale: 'fr-FR', day: 'numeric', month: 'short', year: 'numeric' }).format(d) : '';
}


export const jsonToXlsx = (json, title) => {
  const worksheet = utils.json_to_sheet(json);
  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet, title);
  writeFile(workbook, `${title}.xlsx`, { compression: true });

}


export const calculateDays = (date, dateFin, cb) => {

  if (!date || !dateFin) {
    return 0;
  }

  // To calculate the time difference of two dates 
  const Difference_In_Time = (new Date(dateFin)).getTime() - (new Date(date)).getTime();

  // To calculate the no. of days between two dates 
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const result = (Difference_In_Days > 0) ? Difference_In_Days : 0;
  cb && cb();
  return result;


}

export const toEuro = (price) => (new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR'

})
  .format(isNaN(price) ? 0 : (price / (655.957)))
  .replaceAll(",", " ")
  .replaceAll(".", ",")
  .split("€")
  .reverse()
  .join("") + " €"



);



// const nameFormatter = (name) => {
//   const underscoreRegex = new RegExp("_");
//   const numeroRegex = new RegExp("numero");
//   return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
// }

export const calculateFrais = (frais) => {

  return frais.reduce((sum, current) => {

    return {
      montant: sum.montant + (current.quantite * current.prixUnitaire)
    }

  }, {
    montant: 0
  }).montant;

}


export const calculateFraisAsync = async (frais) => {

  let _frais = await frais.reduce((sum, current) => {

    return {
      montant: sum.montant + (current.quantite * current.prixUnitaire)
    }

  }, {
    montant: 0
  }).montant;

  return _frais;

}



export const toFrench = (arg) => {
  console.log(arg);
  let result = new FrenchNumbersToWords(arg, 'fr').result
  console.log({ result });

  return result.parts ? result.parts.filter((p) => p.number > 0).map((p) => `${p.text} ${p.unit}`).join(' ') + ' de francs CFA' : '';
}


// export const toFrench = (num) => {

//     if (num === 0) {
//       return 'zéro';
//     }
  
//     const units = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];
//     const teens = ['dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'];
//     const tens = ['', '', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'];
//     const hundreds = ['', 'cent', 'deux cents', 'trois cents', 'quatre cents', 'cinq cents', 'six cents', 'sept cents', 'huit cents', 'neuf cents'];
//     const thousands = ['', 'mille', 'deux mille', 'trois mille', 'quatre mille', 'cinq mille', 'six mille', 'sept mille', 'huit mille', 'neuf mille'];
//     const millions = ['', 'un million', 'deux millions', 'trois millions', 'quatre millions', 'cinq millions', 'six millions', 'sept millions', 'huit millions', 'neuf millions'];
  
//     const numStr = num.toString().padStart(9, '0'); // Pad with zeros for easier processing
//     let letterVersion = '';
  
//     for (let i = 0; i < 9; i += 3) {
//       const hundredsDigit = parseInt(numStr[i]);
//       const tensDigit = parseInt(numStr[i + 1]);
//       const unitsDigit = parseInt(numStr[i + 2]);
  
//       if (hundredsDigit > 0) {
//         letterVersion += hundreds[hundredsDigit] + ' ';
//       }
  
//       if (tensDigit === 1) {
//         letterVersion += teens[unitsDigit] + ' ';
//       } else {
//         if (tensDigit > 0) {
//           letterVersion += tens[tensDigit] + ' ';
//           if (unitsDigit > 0 && tensDigit !== 8) {
//             letterVersion += '-';
//           }
//         }
  
//         if (unitsDigit > 0 && tensDigit !== 1 && tensDigit !== 8) {
//           letterVersion += units[unitsDigit] + ' ';
//         }
//       }
  
//       if (i === 0 && letterVersion) {
//         letterVersion += millions[hundredsDigit] + ' ';
//       } else if (i === 3 && letterVersion) {
//         letterVersion += thousands[hundredsDigit] + ' ';
//       }
//     }
  
//     return letterVersion.trim();

// }


export const arrayFilter = (nail, array) => [...array].filter((item) => {
  for (let prop in item) {
    if (nail.test(item[prop])) {
      return true;
    }

  }
  return false;
});


export function toFrench3(n) {
  const units = [
    "zéro",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
  ];
  const teens = [
    "dix",
    "onze",
    "douze",
    "treize",
    "quatorze",
    "quinze",
    "seize",
    "dix-sept",
    "dix-huit",
    "dix-neuf",
  ];
  const tens = [
    "",
    "",
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingt",
    "quatre-vingt-dix",
  ];

  if (n === 0) return "zéro";

  function helper(n) {
    if (n === 0) return "";

    if (n < 10) return units[n];

    if (n < 20) return teens[n - 10];

    const tensDigit = Math.floor(n / 10);
    const unitsDigit = n % 10;

    if (tensDigit === 7 || tensDigit === 9) {
      // Special cases for 70 and 90
      return tens[tensDigit] + (unitsDigit ? "-" + teens[unitsDigit - 1] : "");
    } else {
      return tens[tensDigit] + (unitsDigit ? "-" + units[unitsDigit] : "");
    }
  }

  const numStr = n.toString();
  const len = numStr.length;
  let words = "";

  for (let i = 0; i < len; i++) {
    const digit = parseInt(numStr[i]);
    const place = len - i - 1;

    if (digit !== 0) {
      words += helper(digit) + " ";

      if (place === 1 && digit === 1) {
        // Handle "teen" numbers
        i++;
        words = words.trim() + " ";
      } else if (place === 1) {
        words += "dix ";
      } else if (place === 2) {
        words += "cent ";
      } else if (place === 3 && digit !== 0) {
        words += "mille ";
      } else if (place === 4 && digit !== 0) {
        words += "dix mille ";
      } else if (place === 5 && digit !== 0) {
        words += "cent mille ";
      } else if (place === 6 && digit !== 0) {
        words += "million ";
      }
    }
  }

  return words.trim();
}

