import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  COTATIONS_URL } from "../../settings/APISetting";



export const fetchCotations = createAsyncThunk('fetch-cotations', async () => {


    const response = await fetch(`${COTATIONS_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});
export const createCotation = (cotation, err, cb) => {
    fetch(COTATIONS_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(cotation)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}







export const deleteCotation = (id, err, cb) => {
    fetch(`${COTATIONS_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}


const cotationSlice = createSlice({

    name: 'cotations',
    initialState: {cotations: [], cotationsFetchStatus: '' },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCotations.fulfilled, (state, action) => {

            state.cotationsFetchStatus = 'success';
            state.cotations = [...action.payload];
        }).addCase(fetchCotations.pending, (state, action) => {
            state.cotationsFetchStatus = 'loading';

        }).addCase(fetchCotations.rejected, (state, action) => {
            state.cotationsFetchStatus = 'error';

        });
    }


});
export default cotationSlice;