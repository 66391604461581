


import { priceFormatterV3 } from '../../settings/themeSettings';
import './DashboardTable.scss';


const DashboardTableIII = ({ caption, data, total = 0 }) => {



    return <table class='dashboard-table' style={{ width: '100%' }}>
        <caption> {caption} </caption>
        <tbody style={{ width: '100%' }}>

            {
                Object.keys(data).map((objkey, idx) => {
                    console.log({ q: data[objkey] });
                    return <tr key={idx} style={{ width: '100%' }}>
                        <td>{objkey}</td>
                        <td style={{ textAlign: "right" }}>
                            {
                                priceFormatterV3(
                                    data[objkey].reduce((acc, curr) => {
                                        return acc + curr.montant;
                                    }, 0)
                                )


                            }

                        </td>

                    </tr>
                })




            }
            <tr style={{ width: '100%' }}>
                <td>Total</td>
                <td style={{ textAlign: "right" }}>{ total}</td>
            </tr>
        </tbody>

    </table>;
}



export default DashboardTableIII;